import { combineReducers } from "redux";
import Auth from "./Auth/reducer";
import Layout from "./Layout/reducer";
import Home from "../modules/home/store/reducer";

const rootReducer = combineReducers({
  Auth,
  Layout,
  Home,
});

export default rootReducer;
