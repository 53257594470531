const {
  FETCH_ALL_KIDS_MUSIC,
  FETCH_ALL_KIDS_MUSIC_SUCCESS,
  FETCH_ALL_KIDS_MUSIC_ERROR,
  FETCH_ALL_TOPICS,
  FETCH_ALL_TOPICS_SUCCESS,
  FETCH_ALL_TOPICS_ERROR,
} = require("./actionTypes");

const AllKidsMusicInitialState = {
  allKidsMusicList: [],
  loading: false,
  totalSize: 0,
  topicData: null,
  topicCount: 0,
};

const Home = (state = AllKidsMusicInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ALL_KIDS_MUSIC:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_KIDS_MUSIC_SUCCESS:
      return {
        ...state,
        allKidsMusicList: action.payload.music,
        totalSize: payload.count,
        loading: false,
      };
    case FETCH_ALL_KIDS_MUSIC_ERROR:
      return {
        ...state,
        loading: false,
      };
    case FETCH_ALL_TOPICS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ALL_TOPICS_SUCCESS:
      return {
        ...state,
        topicData: action.payload.rows,
        topicCount: action.payload.count,
        loading: false,
      };
    case FETCH_ALL_TOPICS_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default Home;
