export const SET_IS_AUTH = "SET_IS_AUTH";
export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = "LOGOUT";
export const FETCH_USER_LOADING = "FETCH_USER_LOADING";
export const FETCH_USER = "FETCH_USER";
export const SET_TEMP_EMAIL = "SET_TEMP_EMAIL";

export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_SUCCESS = "SET_SUCCESS";

export const SET_CHATBOX_VISIBILITY = "SET_CHATBOX_VISIBILITY";
